import React from "react"
import 'bootstrap/dist/css/bootstrap.min.css'
import Header from '../components/HeaderWhite/header'
import Footer from '../components/Footer/footer'
import ThankYou from '../components/Thank-you-page/thank-you'
import { Helmet } from "react-helmet";

const content = `Duefocus is a free time tracking software that enables tracking time with timesheets and sends advanced reports`

const Terms = () => (
  <>
    <Helmet>
      <html lang="en" />
      <meta charSet="utf-8" />
      <title>Thank you for the installation!</title>
      <meta name="description" content={content} />
      <meta name="robots" content="noindex, nofollow" />
      <link rel="canonical" href="https://www.duefocus.com/Thank-you/" />
    </Helmet>
    <Header />
    <ThankYou />
    <Footer />
  </>
)

export default Terms