// import { Link } from "gatsby";
import React from "react";
import styles from "./style.module.scss";


import logo from '../img/icons/integrations/Logo_duefocus1.svg'
// import mobileLogo from '../img/logo_parts_animation/Logo_duefocus.svg'
import mobileLogo from '../img/Logo_icon-new-1.svg'

export default class ThankYou extends React.Component {

  handleCloseFeatures = () => {
    // document.getElementById('drop-down').classList.remove('drop-down-active');
    document.getElementById('overlay').removeAttribute('class');
    document.getElementById('drop-down-pricing').classList.remove('drop-down-active');
    document.body.removeAttribute('class');
  }

  render() {
    return (
      <>
        <div onMouseEnter={this.handleCloseFeatures} id='overlay'></div>
        <div className={styles.body}>
          <div className={styles.main}>
            <img src={mobileLogo} width="auto" height="auto" alt="logo" />
            <div className={styles.left}>
              <h1>Thank you for the <span>installation!</span></h1>
              <p>Last step, and we will help you to organize your working routine, here we provide some of the workflows which will be helpful to you, enjoy</p>
            </div>
            <div className={styles.right}>
              <img src={logo} width="auto" height="auto" alt="logo" />
            </div>
          </div>
        </div>
        {/* <div className={styles.firstBlock}>
      <div>
        <div>
        <h2>1</h2>
        <div>
          <h1>Add integration</h1>
          <p>With your tracking system, and we could plan tasks 
              and report directly to your tracking system.</p>
        </div>
        </div>
        <video className={styles.video} muted loop autoPlay>
          <source src={require('../img/Final_2.mp4')} type="video/mp4" />
        </video>
      </div>
    </div>
    <div  id='background-block' className={styles.firstBlock}>
      <div>
        <div>
        <h2>2</h2>
        <div>
          <h1>Make a planning</h1>
          <p>Let's plan our work on some of  your tasks.</p>
        </div>
        </div>
        <video className={styles.video} muted loop autoPlay>
          <source src={require('../img/Final_3.mp4')} type="video/mp4" />
        </video>
      </div>
    </div>
    <div  id='background-last' className={styles.firstBlock}>
      <div>
        <div>
        <h2>3</h2>
        <div>
          <h1>Report it</h1>
          <p>Just report your time and time automatically appear in your tracking system</p>
        </div>
        </div>
        <video className={styles.video} muted loop autoPlay>
          <source src={require('../img/Final_4 .mp4')} type="video/mp4" />
        </video>
      </div>
    </div> */}
      </>
    )
  }
}



